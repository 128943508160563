.sec-privacy,
.sec-imprint {
  padding: 60px 30px;
  .title {
    font-family: "Old Standard TT";
    font-size: 32px;
    line-height: 1em;
    margin-bottom: 30px;
  }

  .subtitle {
    font-family: "Old Standard TT";
    font-size: 24px;
    line-height: 1em;
    margin: 30px 0 15px 0;
  }

  p {
    margin-bottom: 15px;
  }

  .list {
    list-style: circle;
    margin: 0;
    margin-bottom: 15px;
    padding-left: 30px;
  }

  .nlist {
    margin: 0;
    margin-bottom: 15px;
    padding-left: 30px;
  }

  a {
    display: inline-flex;
    align-items: center;
    border-bottom: 1px dashed $blue;
  }
}
