@import "./typography";
@import "./vars";
@import "./reset";
@import "./grid";

body {
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  padding-top: 70px;
}

.page {
  width: 100%;
}

#top,
#service,
#about,
#contact {
  position: absolute;
  top: -70px;
}

.small {
  font-size: 12px;
  line-height: 1.25em;
}

// @media (max-width: 399px) {
//   @import './smartphones.scss';
// }

// @media (min-width: 400px) and (max-width: 1199px) {
//   @import './tablets.scss';
// }

// @media (min-width: 1200px) {
//   @import './desktop.scss';
// }

@import "./page-header.scss";
@import "./hero.scss";
@import "./service.scss";
@import "./contact.scss";
@import "./privacy.scss";
@import "./footer.scss";
