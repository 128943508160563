.sec-footer {
  padding: 15px 30px;
  .address-info {
    margin-bottom: 30px;
  }
  address {
    p {
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
      a {
        border-bottom: 1px dashed $blue;
      }
    }
  }

  .quick-links {
    .vema {
      margin-bottom: 30px;
    }
    a {
      display: inline-flex;
      align-items: center;
      border-bottom: 1px dashed $blue;
      margin-bottom: 5px;
    }
  }

  img {
    width: 200px;
  }

  @media (min-width: 758px) {
    .container {
      display: flex;
      justify-content: space-between;

      > div {
        max-width: 40%;
      }

      .quick-links {
        text-align: right;
      }
    }
  }
}
