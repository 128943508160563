.page-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 100;
  padding: 15px;
  display: flex;
  justify-content: center;
  background-color: white;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .brand {
    height: 40px;

    img {
      height: 100%;
    }
  }

  .page-menu {
    .hamburger-btn {
      height: 40px;
      width: 40px;
      position: relative;
      z-index: 100;

      span {
        position: absolute;
        width: 70%;
        left: 15%;
        height: 2px;
        background-color: $light-grey;
        transition: all 0.2s;

        &:nth-child(1) {
          top: 30%;
          transform: rotate(0deg);
        }

        &:nth-child(2) {
          top: 50%;
          opacity: 1;
        }
        &:nth-child(3) {
          top: 70%;
          transform: rotate(0deg);
        }
      }

      &.open {
        span {
          background-color: $gvsBlue;
          &:nth-child(1) {
            top: 50%;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            top: 50%;
            transform: rotate(-45deg);
          }
        }
      }
    }

    .nav {
      position: fixed;
      top: 0;
      bottom: 0;
      right: -100%;
      height: 100%;
      width: 100%;
      padding: 100px 30px 30px 30px;
      background-color: $light;
      transition: right 0.2s;
      z-index: 99;

      &.open {
        right: 0;
      }
    }
  }

  // @media (min-width: 758px) {
  .page-menu {
    .hamburger-btn {
      display: none;
    }

    .nav {
      position: static;
      top: auto;
      bottom: auto;
      right: auto;
      background-color: transparent;
      display: flex;
      align-items: center;
      padding: 0;

      li {
        margin-left: 30px;
        a {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  // }
}
