.hero {
  background-image: url("../charlesdeluvio-Lks7vei-eAg-unsplash.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  padding: 30px;

  .hero-content {
    width: 100%;
    font-size: 18px;
    line-height: 1.5em;

    .title {
      font-family: "Old Standard TT";
      font-size: 32px;
      line-height: 1em;
      margin-bottom: 30px;
    }

    .contact-btn {
      display: inline-block;
      appearance: none;
      outline: none;
      border: none;
      border-radius: 5px;
      height: 45px;
      padding: 15px 30px;
      line-height: 15px;
      background-color: $blue;
      color: white;
      margin-top: 30px;
      font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
      font-size: 15px;

      > div {
        display: flex;
        align-items: center;

        span {
          margin-left: 5px;
        }
      }

      &.info-btn {
        display: none;
      }
    }
  }

  @media (min-width: 400px) {
    .hero-content {
      .title {
        font-size: 36px;
      }
    }
  }

  @media (min-width: 758px) {
    padding: 45px 15px;
    .hero-content {
      width: 60%;

      .title {
        font-size: 64px;
      }

      .contact-btn {
        &.phone-btn {
          display: none;
        }

        &.info-btn {
          display: inline-block;
        }
      }
    }
  }
}
