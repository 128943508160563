.title {
  font-family: 'Old Standard TT';
  font-size: 32px;
  line-height: 1em;
  margin-bottom: 30px;
}


/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/source-sans-pro-v21-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-sans-pro-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/source-sans-pro-v21-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-900 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/source-sans-pro-v21-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin-900.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* zilla-slab-300 - latin */
@font-face {
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/zilla-slab-v11-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/zilla-slab-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/zilla-slab-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/zilla-slab-v11-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/zilla-slab-v11-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/zilla-slab-v11-latin-300.svg#ZillaSlab') format('svg'); /* Legacy iOS */
}
/* zilla-slab-regular - latin */
@font-face {
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/zilla-slab-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/zilla-slab-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/zilla-slab-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/zilla-slab-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/zilla-slab-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/zilla-slab-v11-latin-regular.svg#ZillaSlab') format('svg'); /* Legacy iOS */
}

/* zilla-slab-300 - latin */
@font-face {
  font-family: 'Old Standard TT';
  font-style: normal;
  font-weight: 400;
  // src: url('../fonts/zilla-slab-v11-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
      //  url('../fonts/zilla-slab-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      //  url('../fonts/zilla-slab-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/OldStandardTT-Regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/OldStandardTT-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
      //  url('../fonts/zilla-slab-v11-latin-300.svg#ZillaSlab') format('svg'); /* Legacy iOS */
}
/* zilla-slab-regular - latin */
@font-face {
  font-family: 'Old Standard TT';
  font-style: normal;
  font-weight: 600;
  // src: url('../fonts/zilla-slab-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
      //  url('../fonts/zilla-slab-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      //  url('../fonts/zilla-slab-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/OldStandardTT-Bold.woff') format('woff'), /* Modern Browsers */
       url('../fonts/OldStandardTT-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
}