.sec-contact {
  margin: 30px 0;
  padding: 30px;
  background-color: $light;

  .contact-info {
    text-align: center;
    margin: 30px 0;
    img {
      display: inline-block;
      width: 75%;
      border-radius: 5px;
    }

    .contact-info-details {
      .contact-info-details-role {
        line-height: 20px;
      }
      .contact-info-details-name {
        font-size: 36px;
        font-family: "Old Standard TT", "Times New Roman", Times, serif;
      }

      a {
        display: inline-flex;
        align-items: center;
        line-height: 20px;

        svg {
          margin-right: 5px;
        }
      }
    }
  }

  @media (min-width: 758px) {
    padding: 60px 15px;

    .container {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
    }

    .contact-info {
      display: flex;
      align-items: center;
      margin: 0;
      text-align: left;

      img {
        width: 100px;
        margin-right: 30px;
      }

      .contact-info-details {
        .contact-info-details-role {
          line-height: 30px;
        }
        .contact-info-details-name {
          font-size: 36px;
          font-family: "Old Standard TT", "Times New Roman", Times, serif;
        }

        a {
          display: flex;
          align-items: center;
          line-height: 30px;

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    img {
      width: 200px;
      margin-right: 30px;
    }
  }
}
