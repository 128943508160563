html {
    font-size: 18px;
    scroll-behavior: smooth;
  }
  
  html,
  body {
    height: 100%;
  }
  
  // *********************************************
  // HTML Elements - MDN 26. Jan 2017
  // *********************************************
  // Sections
  body,
  section,
  nav,
  article,
  aside,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  header,
  footer,
  address,
  main, // Grouping
  p,
  hr,
  pre,
  blockquote,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  figure,
  figcaption,
  div, // Semantic Text-Elements
  a,
  em,
  strong,
  small,
  s,
  cite,
  q,
  dfn,
  abbr,
  data,
  time,
  code,
  var,
  samp,
  kbd,
  sub,
  sup,
  i,
  b,
  u,
  mark,
  ruby,
  rt,
  rp,
  bdi,
  bdo,
  span,
  br,
  wbr, // Document Changer
  ins,
  del, // Embedded Content
  img,
  iframe,
  embed,
  object,
  param,
  video,
  audio,
  source,
  track,
  canvas,
  map,
  area,
  svg,
  math, // Table Data
  table,
  caption,
  colgroup,
  col,
  tbody,
  thead,
  tfoot,
  tr,
  td,
  th, // Forms
  form,
  fieldset,
  legend,
  label,
  input,
  button,
  select,
  datalist,
  optgroup,
  option,
  textarea,
  keygen,
  output,
  progress,
  meter, // Interactive Elements
  details,
  summary,
  menu {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  a,
  a:hover {
    color: inherit;
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }
  
  img {
    border: 0;
    display: block;
  }
  
  ul {
    list-style: none;
  }