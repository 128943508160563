.section {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.container {
  width: 100%;

  @media (min-width: 1200px) {
    width: 1200px;
  }
}

