// COLORS
$dark: #38424c;
$dark-grey: #5e6e7f;
$grey: #74889c;
$light-grey: #98b3ce;
$light: #d6eaff;
$white: #DBD5D3;

$gvsBlue: #014278;

$pink: #ff7ab2;
$blue: #028DFF;
$cyan: #47b5d7;
$teal: #83c9bc;
$purple: #d0a9ff;
$violet: #8521ff;
$beige: #beaf62;
$yellow: #ffd700;
$red: #fc6a5d;
$green: #a1d07e;
$orange: #FFA75A;
