.sec-service{
  padding: 30px 30px;

  span {
    display: block;
    margin-bottom: 30px;
    font-size: 18px;
  }

  img {
    width: 400px;
  }
}

.svc-customers {
  padding: 0 30px;

  .customers {
    margin-bottom: 30px;

    p {
      font-size: 18px;
    }

    .title {
      text-align: center;
    }
  }

  @media (min-width: 758px) {
    .container {
      display: flex;
      justify-content: space-between;

      .customers {
        width: 45%;

        p {
          font-size: 18px;
        }

        .title {
          text-align: center;
        }
      }
    }
  }
  
}